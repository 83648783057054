<template>
  <div class="switch-container">
    <label class="md_switch">
      <span class="label">{{ label }}</span>
      <input type="checkbox" v-model="value"/>
      <span class="md_switch__toggle"></span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'ToggleSwitch',
    props: {
      label: {
        type: String,
        default: "Nur kostenlose Angebote",
      },
      fieldTypeId: {
        default: null,
      },
      labelMarginRight: {
        type: Number,
        default: 110,
      },
      selectedFilters: {
        type: Map,
        default: null,
      }
    },
    data() {
      return {
        value: false,
      }
    },
    watch: {
      value(value) {
        this.$emit('sliderChanged', this.fieldTypeId, this.label, value);
      },
      selectedFilters: {
        handler() {
          /*trying to set preset values*/
          if(this.selectedFilters!==null){
            var res = this.selectedFilters.get(this.fieldTypeId);
            if(res!==null && res !== undefined){
              /*we usually get an array at this point with one value - we'll have to extract this value*/
              if(Array.isArray(res) && res.length>0){
                var value = res.pop();
                if(value !== null){
                  this.value = true;
                }
              }
            }
          }      
        },
      // force eager callback execution
      immediate: true
    }
  },
  computed: {
    labelRightMargin () {
      return 'margin-right:' + this.labelMarginRight + 'px';
    }  
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>

  .switch-container {
    width: 100%;
  }

  body {
    background: #F5F5F5;
  }

  .label{
    font-family: 'elliot-regular', sans-serif;
    font-weight: normal;
    font-weight: bold;
  }

  .md_switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
  }

  .md_switch .md_switch__toggle {
    position: relative;
    cursor: pointer;
  }

  .md_switch [type=checkbox] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

/* default states */

.md_switch .md_switch__toggle::before,
.md_switch .md_switch__toggle::after {
  content: '';
  display: block;
  margin: 0 3px;
  transition: all 100ms cubic-bezier(0.4, 0.0, 0.2, 1);
  box-shadow: 1px 1px 1px grey;
  background: #ddd;
}

.md_switch .md_switch__toggle::before {
  height: 0.95em;
  width: 3em;
  border-radius: 0.65em;
  opacity: 0.45;
}

.md_switch .md_switch__toggle::after {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 1.7em;
  width: 1.7em;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0,0,0,0.2), 0 0 2px rgba(0,0,0,0.4);
}

/* special states */
.md_switch [type=checkbox]:focus+.md_switch__toggle {
  outline: #E5321B solid 1px;
  box-shadow: 0 0 8px #E5321B;
}

.md_switch [type=checkbox]:disabled+.md_switch__toggle {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: 0.6;
}

.md_switch [type=checkbox]:disabled+.md_switch__toggle::after {
  box-shadow: none;
}

.md_switch__toggle {
  margin-right: 5px;
}

/* checked states */

.md_switch [type=checkbox]:checked+.md_switch__toggle::before,
.md_switch [type=checkbox]:checked+.md_switch__toggle::after{
  background: #E5321B;
}

.md_switch [type=checkbox]:checked+.md_switch__toggle::after {
  transform: translate(calc(3em - 100%), -50%);
}


body[dir="rtl"] {
  .md_switch [type=checkbox]:checked+.md_switch__toggle::after {
    transform: translate(calc(0em - 100%), -50%);
  }

  .md_switch__toggle {
    margin-left: 5px;
    margin-right: 0px;
  }
}

</style> 